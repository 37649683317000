import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const StorybookEmbed = makeShortcode("StorybookEmbed");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Font sizes are smaller on mobile and scale up at the `}<inlineCode parentName="p">{`md`}</inlineCode>{` `}<a parentName="p" {...{
        "href": "/foundations/css-utilities/grid#breakpoints"
      }}>{`breakpoint`}</a>{` to be larger on desktop.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Scale`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font size: mobile`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font size: desktop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`1.25 line height`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`1.5 line height`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`00`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`60`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`72`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`60`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`26px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`22px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`36`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`25`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`17.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`21`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Heading utilities`}</h2>
    <p>{`Use `}<inlineCode parentName="p">{`.h1`}</inlineCode>{` – `}<inlineCode parentName="p">{`.h6`}</inlineCode>{` to change an elements font size and weight to match our heading styles.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-typography--heading'
    }]} height="260" mdxType="StorybookEmbed" />
    <p>{`These are particularly useful for changing the visual appearance while keeping the markup semantically correct. Be sure you keep the hierarchy appropriate for the page.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-typography--heading-semantic'
    }]} height="100" mdxType="StorybookEmbed" />
    <h2>{`Type scale utilities`}</h2>
    <p>{`Use `}<inlineCode parentName="p">{`.f1`}</inlineCode>{` – `}<inlineCode parentName="p">{`.f6`}</inlineCode>{` to change an elements font size while keeping inline with our type scale.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-typography--font-size'
    }]} height="280" mdxType="StorybookEmbed" />
    <p>{`Lighter font-weight utilities are available in a limited range: `}<inlineCode parentName="p">{`.f00-light`}</inlineCode>{` - `}<inlineCode parentName="p">{`.f3-light`}</inlineCode>{`. Lighter font-weights reduce the legibility of text, particularly at small font sizes, so the scale only goes down to `}<inlineCode parentName="p">{`f3`}</inlineCode>{` at 20px. The larger sizes`}<inlineCode parentName="p">{`f0`}</inlineCode>{` and `}<inlineCode parentName="p">{`f00`}</inlineCode>{` allow for lighter and larger type that is in keeping with our marketing styles.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-typography--font-size-light'
    }]} height="280" mdxType="StorybookEmbed" />
    <h2>{`Line height styles`}</h2>
    <p>{`Change the line height density with these utilities: `}<inlineCode parentName="p">{`.lh-default`}</inlineCode>{`, `}<inlineCode parentName="p">{`.lh-condensed`}</inlineCode>{`, `}<inlineCode parentName="p">{`.lh-condensed-ultra`}</inlineCode>{`. Responsive variants are also available (e.g. `}<inlineCode parentName="p">{`.lh-sm-condensed`}</inlineCode>{`).`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-typography--line-height'
    }]} height="280" mdxType="StorybookEmbed" />
    <p>{`The `}<inlineCode parentName="p">{`lh-0`}</inlineCode>{` utility class sets `}<inlineCode parentName="p">{`line-height: 0 !important`}</inlineCode>{`, and can be used to remove vertical spacing from elements that inherit line-height but don't contain any text.`}</p>
    <h2>{`Typographic styles`}</h2>
    <p>{`Change the font weight, styles, and alignment with these utilities.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-typography--typographic-styles'
    }]} height="600" mdxType="StorybookEmbed" />
    <h2>{`Word-break`}</h2>
    <p>{`There are two utilities for adjusting how lines and words of text break when they exceed the width of their containing element:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><inlineCode parentName="p">{`wb-break-word`}</inlineCode>{` sets `}<inlineCode parentName="p">{`word-break: break-word`}</inlineCode>{` and `}<inlineCode parentName="p">{`overflow-wrap: break-word`}</inlineCode>{`, which will only break words if they would exceed the line length `}<em parentName="p">{`after wrapping`}</em>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><inlineCode parentName="p">{`wb-break-all`}</inlineCode>{` sets `}<inlineCode parentName="p">{`word-break: break-all`}</inlineCode>{`, which will force a word to break regardless of whether it's shorter than the line length. See `}<a parentName="p" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/word-break#Values"
          }}>{`MDN's `}<inlineCode parentName="a">{`word-break`}</inlineCode>{` docs`}</a>{` for more info.`}</p>
      </li>
    </ol>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-typography--word-break'
    }]} height="410" mdxType="StorybookEmbed" />
    <h2>{`Text alignment`}</h2>
    <p>{`Use text alignment utilities `}<inlineCode parentName="p">{`.text-left`}</inlineCode>{`, `}<inlineCode parentName="p">{`.text-center`}</inlineCode>{`, and `}<inlineCode parentName="p">{`.text-right`}</inlineCode>{` to left align, center, or right align text.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-typography--text-alignment'
    }]} height="150" mdxType="StorybookEmbed" />
    <h2>{`Responsive text alignment`}</h2>
    <p>{`Use the following formula to make a text alignment utility responsive: `}<inlineCode parentName="p">{`.text-[breakpoint]-[alignment]`}</inlineCode>{`.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-typography--text-alignment-responsive'
    }]} height="100" mdxType="StorybookEmbed" />
    <h2>{`List styles`}</h2>
    <p>{`Remove bullets from an unordered list or numbers from an ordered list by applying `}<inlineCode parentName="p">{`.list-style-none`}</inlineCode>{` to the `}<inlineCode parentName="p">{`<ul>`}</inlineCode>{`.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-typography--list'
    }]} height="100" mdxType="StorybookEmbed" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      